import React from "react";

const Email = ({email, className = '', label = 'Send en epost'}) => {
    let parts = email.split('@')

    const sendMail = (e) => {
        e.preventDefault();
        window.location = `mailto:${e.target.dataset.user}@${e.target.dataset.domain}`
    }

    return (
        <div className='flex'>
            <a className={`${className}`} data-user={parts[0]} data-domain={parts[1]}
               onClick={sendMail}
               href='#'>{label}</a>
        </div>
    )
}

export default Email;