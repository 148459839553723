import React from 'react'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import Carousel from '../components/carousel'
import PortableText from '../components/portableText'
import FeatureBox from "../components/featureBox";
import Share from "../components/share";
import {getFullUrl, getPostUrl} from "../lib/helpers";
import Article from "../components/article";
import {format} from "date-fns";
import Email from "../components/email";
import PageDecal from "../assets/page-decal.svg";
import Container from "../components/container";

export const query = graphql`
query JobPageQuery($id: String) {
    job: sanityJob(_id: {eq: $id}) {
        id
        mainImage {
            ...MainImage
        }
        title
        deadlineAt
        slug {
          current
        }
        about {
            title
            features
        }
        contact {
            _key
            author {
            ...AuthorInfo
            }
        }
        _rawExcerpt(resolveReferences: {maxDepth: 5})
        _rawBody(resolveReferences: {maxDepth: 5})
    }
}
`

class JobPage extends React.Component {
    constructor(props) {
        super(props)
        const {data, errors} = props
        this.state = {
            job: (data || {}).job,
        }

        console.log(props)
    }


    splitTitle(name) {
        if (name.includes(':')) {
            let fragments = name.split(':')
            return {
                product: fragments[0],
                name: fragments[1]
            }
        }
        return {
            product: null,
            name
        }
    }

    render() {
        let content = {
            title: 'Om stillingen',
            features: [
                'Søknadsfrist: ' + format(this.state.job.deadlineAt, 'DD/MM/YYYY'),
            ]
        }

        const SidebarBox = () => (
            <>
                {this.state.job.contact.length && (
                    <Email
                        className='rounded-3xl bg-brand-red text-white py-4 text-xl flex-1 text-center mb-4'
                        label="Søknad sendes til jobb@isi.no!"
                        email={this.state.job.contact[0].author.email}/>
                )}

                <FeatureBox content={this.state.job.about}/>
                <div className="mt-8 mb-4">
                    {this.state.job.contact.map(({author}) => (
                        <div className='flex mb-4 items-center'>
                            <div className='w-1/3 rounded-3xl overflow-hidden mr-4'>
                                <Img
                                    fluid={author.image.asset.fluid}
                                    className="object-cover"
                                />
                            </div>
                            <div className='flex-1'>
                                <div className="text-xl font-bold text-brand-blue">{author.name}</div>
                                <div className='mb-4'>{author.position}</div>
                                <Email
                                    className='rounded-2xl bg-brand-blue text-white py-2 px-4'
                                    email={author.email}/>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
        return (
            <>
                <SEO
                    title={this.state.job.title}
                    image={this.state.job.images ? this.state.job.images[0] : null}
                />

                <img src={PageDecal} className='absolute top-0 left-0 z-20 max-w-xs' />
                <Container className='relative md:pt-24 pb-24 z-30'>
                    <div className='md:px-130 flex flex-col '>
                        <h2 className='text-2xl md:text-5xl mb-6 text-brand-red font-semibold leading-title font-heading max-w-3xl tracking-tight order-2'>
                            <div className='flex items-end pt-8 md:pt-0'>
                                {this.splitTitle(this.state.job.title).product && (
                                    <div
                                        className='mr-3 text-3xl mb-5px'>{this.splitTitle(this.state.job.title).product}:</div>
                                )}
                                <div>{this.splitTitle(this.state.job.title).name}</div>
                            </div>
                        </h2>
                        <div className='text-base mb-6 max-w-xl font-medium leading-normal order-3'>
                            <PortableText blocks={this.state.job._rawExcerpt} />
                        </div>

                        <div className='-mx-8 md:flex order-5'>
                            <div className="px-8 md:w-3/5">

                                <div className="mb-8 md:mb-0">
                                    <div className="body-text">
                                        {this.state.job._rawBody &&
                                            <PortableText blocks={this.state.job._rawBody} />}
                                    </div>

                                    <Share
                                        url={getFullUrl(`/job/${this.state.job.slug.current}`)}
                                        title={this.state.job.title} />
                                </div>
                            </div>
                            <div className="px-8 flex-1">
                                <SidebarBox />
                            </div>
                        </div>
                    </div>
                </Container>
            </>
        )
    }
}

export default JobPage
